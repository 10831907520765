import React from "react"
import { useState, useEffect } from "react"
const ProgressiveImg = ({ placeholderSrc, src, className, ...props }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      setImgSrc(src)
    }
  }, [src])

  return (
    <img
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ""}
      className={className}
    />
  )
}
export default ProgressiveImg
