import React from "react"
import { Button, Col, Row, Typography } from "antd"
import { useRef } from "react"
import pict1PlaceHolder from "resources/img/solution/pict1Placeholder.png"
import pict2PlaceHolder from "resources/img/solution/pict2Placeholder.png"
import pict3PlaceHolder from "resources/img/solution/pict3Placeholder.png"
import pict4PlaceHolder from "resources/img/solution/pict4Placeholder.png"
import pict5PlaceHolder from "resources/img/solution/pict5Placeholder.png"
import pict6PlaceHolder from "resources/img/solution/pict6Placeholder.png"
import pict7PlaceHolder from "resources/img/solution/pict7Placeholder.png"
import pict1 from "resources/img/solution/pict1.png"
import pict2 from "resources/img/solution/pict2.png"
import pict3 from "resources/img/solution/pict3.png"
import pict4 from "resources/img/solution/pict4.png"
import pict5 from "resources/img/solution/pict5.png"
import pict6 from "resources/img/solution/pict6.png"
import pict7 from "resources/img/solution/pict7.png"
import check from "resources/img/shape/list.svg"
import { Autoplay, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ProgressiveImg from "helper/ProgresiveImage"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
const Solutions = () => {
  const myRef = useRef()
  const swiperRef = useRef()
  const imgPth = [
    {
      title: "Streamlining and reducing cost of fleet operation",
      path: pict1,
      placeHolder: pict1PlaceHolder,
      list: [
        "Tracking, managing, and analyzing its vehicles, drivers, mechanics in a single platform.",
        "Monitoring of vehicles and logistics equipment to optimize movement and deliveries. ",
      ],
    },
    {
      title: "Supporting data centers in achieving zero down time",
      path: pict2,
      placeHolder: pict2PlaceHolder,
      list: [
        "Data integration and analysis for better decision-making and more efficient operations management.",
        "Managing and monitoring the fuel for the backup generators in real-time.",
      ],
    },
    {
      title:
        "Helping mining companies to optimize fuel usage and minimize fuel theft",
      path: pict3,
      placeHolder: pict3PlaceHolder,
      list: [
        "Monitoring mileage and fuel consumption to detect indications abnormal fuel usage via FMS.",
        "Integrating FMS with fleet management systems to combine fuel consumption data with operational data.",
      ],
    },
    {
      title:
        "Helping companies in reducing the risk of accident for its drivers",
      path: pict4,
      placeHolder: pict4PlaceHolder,
      list: [
        "Monitoring driver behavior and condition while operating the vehicles.",
        "Facial recognition to identify drivers and ensure only authorized drivers can operate the vehicles.",
      ],
    },
    {
      title:
        "Helping banks in reducing significant cost of managing records and documents of its customers",
      path: pict5,
      placeHolder: pict5PlaceHolder,
      list: [
        "Integrating physical and digital records, keeping everything safe, efficient, and accessible.",
        "Ability to quickly search and retrieve documents using keywords, tags, or metadata.",
      ],
    },
    {
      title:
        "Helping e-bikes companies giving its customers a peace of mind with theft prevention",
      path: pict6,
      placeHolder: pict6PlaceHolder,
      list: [
        "Enabling real-time track and trace using mobile app.",
        "Real-time data analysis to enhance operational efficiency and productivity.",
      ],
    },
    {
      title:
        "Helping the government and fertilizer manufacturers to optimize subsidized product distribution",
      path: pict7,
      placeHolder: pict7PlaceHolder,
      list: [
        "Integrating and tracking product movements in every distribution centers",
        "A centralized data storage hub that collects inventory information from various locations, facilitating cross-location analysis",
      ],
    },
  ]

  return (
    <>
      <div
        id="solution"
        className="index-container"
        // data-aos="fade-up"
        ref={myRef}
      >
        <div className="our-solutions-section">
          <Row className="container-row" style={{ marginBottom: 40 }}>
            <Col span={10} className="col-left">
              <Typography.Text className="title">Our Solutions</Typography.Text>
            </Col>
            <Col span={10} className="col-right slide-navigation">
              <Button
                className="sol-nav"
                onClick={() => swiperRef.current.swiper.slidePrev()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.57 6.89166L3.5 12.9617L9.57 19.0317M20.5 12.9617H3.67"
                    stroke="#D0D6DD"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
              <Button
                className="sol-nav"
                onClick={() => swiperRef.current.swiper.slideNext()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.43 6.89166L20.5 12.9617L14.43 19.0317M3.5 12.9617H20.33"
                    stroke="#D0D6DD"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </Col>
          </Row>
          <Row className="container-row">
            <Col>
              <Row className="container-row row-slide">
                <Swiper
                  ref={swiperRef}
                  spaceBetween={20}
                  loop={true}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 16,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 16,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                  }}
                  modules={[Autoplay, Navigation, Pagination]}
                  className="mySwiper"
                >
                  {imgPth.map((item, index) => (
                    <SwiperSlide key={index}>
                      <Col className="Card">
                        <ProgressiveImg
                          src={item?.path}
                          placeholderSrc={item?.placeHolder}
                          className="solution-img"
                        />
                        <div className="benefit-contain">
                          <p className="title">{item.title}</p>

                          {item.list.map((list, key) => (
                            <div className="list" key={key}>
                              <img
                                className="check-icon"
                                src={check}
                                alt="check"
                              />
                              <p>{list}</p>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </SwiperSlide>
                  ))}
                </Swiper>

                <Col span={24} className="col-right slide-navigation-bottom">
                  <Button
                    className="sol-nav"
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9.57 6.89166L3.5 12.9617L9.57 19.0317M20.5 12.9617H3.67"
                        stroke="#D0D6DD"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                  <Button
                    className="sol-nav"
                    onClick={() => swiperRef.current.swiper.slideNext()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.43 6.89166L20.5 12.9617L14.43 19.0317M3.5 12.9617H20.33"
                        stroke="#D0D6DD"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Solutions
