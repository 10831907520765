import React from "react"
import { Button, Col, Row, Typography } from "antd"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/pagination"
import { Navigation } from "swiper"

import "aos/dist/aos.css"
import { useRef } from "react"
import pict1 from "resources/img/benefits/image 1.png"
import pict2 from "resources/img/benefits/image 2.png"
import pict3 from "resources/img/benefits/image 3.png"
import pict1PlaceHolder from "resources/img/benefits/placeholder/image 1.png"
import pict2PlaceHolder from "resources/img/benefits/placeholder/image 2.png"
import pict3PlaceHolder from "resources/img/benefits/placeholder/image 3.png"
import ProgressiveImg from "helper/ProgresiveImage"

const Benefits = () => {
  const myRef = useRef()
  const benefitSwiperRef = useRef()

  return (
    <>
      <div
        id="benefit"
        className="index-container"
        // data-aos="fade-up"
        ref={myRef}
      >
        <div className="our-benefits-section">
          <Row className="container-row" style={{ marginBottom: 40 }}>
            <Col span={10} className="col-left">
              <Typography.Text className="title">Our Benefits</Typography.Text>
            </Col>
            <Col span={10} className="col-right slide-navigation">
              <Button
                className="benefit-nav"
                onClick={() => benefitSwiperRef.current.swiper.slidePrev()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M9.57 6.89166L3.5 12.9617L9.57 19.0317M20.5 12.9617H3.67"
                    stroke="#191919"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
              <Button
                className="benefit-nav"
                onClick={() => benefitSwiperRef.current.swiper.slideNext()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M14.43 6.89166L20.5 12.9617L14.43 19.0317M3.5 12.9617H20.33"
                    stroke="#191919"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </Col>
          </Row>
          <Row className="container-row slider-inside">
            <Col>
              <Row className="container-row row-slide">
                <Swiper
                  spaceBetween={20}
                  ref={benefitSwiperRef}
                  loop={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <Col className="Card">
                      <ProgressiveImg
                        src={pict1}
                        placeholderSrc={pict1PlaceHolder}
                        className="solution-img"
                      />
                      <div className="benefit-contain">
                        <p className="title">Tailored Solutions</p>
                        <div className="list">
                          We understand your unique business needs and deliver
                          tailor-made solutions crafted to align seamlessly with
                          your goals. Our team works closely with you to create
                          a solution that amplifies your strengths and addresses
                          your challenges, giving you a competitive edge.
                        </div>
                      </div>
                    </Col>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <Col className="Card">
                      <ProgressiveImg
                        src={pict2}
                        placeholderSrc={pict2PlaceHolder}
                        className="solution-img"
                      />
                      <div className="benefit-contain">
                        <p className="title">Seamless Managed Services</p>
                        <div className="list">
                          Leave the complexities to us. Our comprehensive
                          managed services allow you to focus on what you do
                          best while we take care of the rest. From setup and
                          optimization to ongoing support, we ensure your
                          journey with our solutions is smooth, hassle-free, and
                          geared for success.
                        </div>
                      </div>
                    </Col>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Col className="Card">
                      <ProgressiveImg
                        src={pict3}
                        placeholderSrc={pict3PlaceHolder}
                        className="solution-img"
                      />
                      <div className="benefit-contain">
                        <p className="title">Powered by The Latest Tech</p>
                        <div className="list">
                          Discover innovation at its finest. Our solutions are
                          driven by the cutting-edge power of the latest
                          technologies, ensuring you stay ahead in today's
                          dynamic business landscape. Experience the future of
                          possibilities with our state-of-the-art offerings.
                        </div>
                      </div>
                    </Col>
                  </SwiperSlide>
                </Swiper>
                <Col span={10} className="col-right slide-navigation-bottom">
                  <Button
                    className="benefit-nav"
                    onClick={() => benefitSwiperRef.current.swiper.slidePrev()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M9.57 6.89166L3.5 12.9617L9.57 19.0317M20.5 12.9617H3.67"
                        stroke="#191919"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                  <Button
                    className="benefit-nav"
                    onClick={() => benefitSwiperRef.current.swiper.slideNext()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M14.43 6.89166L20.5 12.9617L14.43 19.0317M3.5 12.9617H20.33"
                        stroke="#191919"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Benefits
