import React from "react"
import { Col, Row, Typography } from "antd"
import videoHero from "resources/img/hero2.mp4"
import teltonika from "resources/img/partnericon/teltonika.png"
import telkomsel from "resources/img/partnericon/telkomsel.png"
import aws from "resources/img/partnericon/aws.png"
import infomedia from "resources/img/partnericon/infomedia.png"
import { useRef } from "react"
import { useEffect } from "react"
// import { Link } from "gatsby"
const TopSectionBG = () => {
  const videoEl = useRef(null)
  const attemptPlay = () => {
    videoEl && videoEl.current && videoEl.current.play()
  }

  useEffect(() => {
    attemptPlay()
  }, [])
  return (
    <>
      <div className="top-area">
        <Col xl={20} lg={20} md={24} sm={24} xs={24}>
          <div className="top-area-content">
            <Col xl={14} lg={14} md={24} sm={24} xs={24} className="top-col">
              <div className="container-text-mobile-center">
                <Typography.Text className="text-big-banner">
                  Unlocking your business growth with enterprise-grade IoT
                  solution.
                </Typography.Text>
              </div>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="container-text-normal-banner">
                  <Typography.Text className="text-normal-banner">
                    Seamlessly integrating technology with your unique business
                    needs. Elevate operations, harness data, and drive growth
                    with tailored, enterprise-grade IoT innovation.
                  </Typography.Text>
                </div>
              </Col>
              {/* <Col md={24} sm={24} xs={24}>
                <Row className="button-top">
                  <Link to={"#download"}>
                    <Button className="button-black">
                      <p>Contact us</p>
                    </Button>
                  </Link>
                </Row>
              </Col> */}
            </Col>
            <Col xl={6} lg={6} md={24} sm={24} xs={24}>
              {" "}
              <div className="img-area">
                <video
                  playsInline
                  loop
                  muted
                  // controls
                  className="video-top"
                  alt="All the devices"
                  src={videoHero}
                  ref={videoEl}
                />
              </div>
            </Col>
          </div>
        </Col>
      </div>
      <div className="bottom-area">
        <Col xl={20} lg={20} md={24} sm={24} xs={24}>
          <div className="top-area-content-bot">
            <Row style={{ width: "100%" }}>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                className="trusted-partner"
              >
                <p className="trusted-partner-text">
                  Trusted by best partners in Indonesia
                </p>
              </Col>
              <Col
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="trusted-partner-icon-wrap"
              >
                <div className="trusted-partner-icon">
                  <img
                    className="teltonika-icon"
                    src={teltonika}
                    alt="teltonika"
                  />
                </div>
                <div className="trusted-partner-icon">
                  <img
                    className="telkomsel-icon"
                    src={telkomsel}
                    alt="telkomsel"
                  />
                </div>
                <div className="trusted-partner-icon">
                  <img className="aws-icon" src={aws} alt="aws" />
                </div>
                <div className="trusted-partner-icon">
                  <img
                    className="infomedia-icon"
                    src={infomedia}
                    alt="infomedia"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </div>
    </>
  )
}

export default TopSectionBG
