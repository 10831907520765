import * as React from "react"
import "resources/style/style.less"
import TopSectionBG from "components/section/content/top.sc"
import Seo from "../components/seo"
import Layout from "components/layout"
import imageUrl from "resources/img/logo-wispay.png"
import "@fontsource/inter"
import Solutions from "components/section/content/Solutions"
import Benefits from "components/section/content/Benefits"
// import Partner from "components/section/content/Partner"
import Team from "components/section/content/Team"

const IndexPage = () => {
  return (
    <>
      <Seo
        title={"Bintang Digital Asia | BDA-IoT"}
        description={
          "Unlocking your business growth with enterprise-grade IoT solution."
        }
        imageUrl={imageUrl}
      />
      <Layout headerVariant="normal" headerBackground="blue">
        <TopSectionBG />
        <Solutions />
        <Benefits />
        {/* <Partner /> */}
        <Team />
      </Layout>
    </>
  )
}

export default IndexPage
