import React from "react"
import { Button, Col, Row } from "antd"

import "swiper/css"
import "swiper/css/pagination"

import "aos/dist/aos.css"
import { useRef } from "react"

const Team = () => {
  const myRef = useRef()

  return (
    <>
      <div id="team" className="index-container" ref={myRef}>
        <div className="our-team-section">
          <Row className="container-row" style={{ marginBottom: 40 }}>
            <Col span={24} className="container-col">
              <Row className="team-contain">
                <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                  <p className="title">
                    Solve problems and unlock your business growth now.
                  </p>
                  <p className="sub-title">
                    Let us hear more from you and partner up to elevate your
                    business.
                  </p>
                  <a href="mailto:info@bintangdigitalasia.com">
                    <Button className="button-black">
                      <p className="text">Get Started</p>
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Team
